import React, { Component } from 'react'
import MainPictures from '../main-pictures/main-pictures'
import About from '../about/about'
import Contents from '../contents/contents'

export default class Main extends Component {
    render() {
        return (
            <div>
                <MainPictures />
                <About />
                <Contents />
            </div>
        )
    }
}
