import React, { Component } from 'react'
import {Container, Table, Row, Col} from 'react-bootstrap';
import { FaPhone, FaRegEnvelope, FaMapMarkerAlt } from "react-icons/fa";

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <Container>
                    <Row>
                        <Col id="hours" style={{marginBottom: 20}} xs={12} md={5}>
                            <h3 className="text-center" style={{margin: 10}}>Hours</h3>
                            <Table striped hover size="sm">
                                <tbody>
                                    <tr>
                                        <td><strong>Monday</strong></td>
                                        <td>7:00AM - 4:00PM</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Tuesday</strong></td>
                                        <td>7:00AM - 4:00PM</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Wednesday</strong></td>
                                        <td>7:00AM - 4:00PM</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Thursday</strong></td>
                                        <td>7:00AM - 4:00PM</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Friday</strong></td>
                                        <td>7:00AM - 4:00PM</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Saturday</strong></td>
                                        <td>7:00AM - 12:00PM</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Sunday</strong></td>
                                        <td>Closed</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col style={{marginBottom: 20}} xs={12} md={2}>
                        </Col>
                        <Col id="contact"  style={{marginBottom: 20}} xs={12} md={5}>
                            <h3 className="text-center" style={{margin: 10}}>Contact Us</h3>
                            <h5 style={{fontSize:15}}>
                                <FaPhone />&nbsp;<a href="tel:1-415-822-1212">(415) 822-1212</a>
                            </h5>
                            <h5 style={{fontSize:15}}>
                                <FaRegEnvelope />&nbsp;<a href="mailto:aceroofingsupplies@gmail.com">aceroofingsupplies@gmail.com</a>
                            </h5>
                            <h5 style={{fontSize:15}}>
                                <FaMapMarkerAlt />&nbsp;<a href="https://www.google.com/maps/place/1296+Armstrong+Ave,+San+Francisco,+CA+94124/@37.7229036,-122.3881681,17z/data=!3m1!4b1!4m5!3m4!1s0x808f7f3db50b7825:0x70c9b06c3c511fcf!8m2!3d37.7228994!4d-122.3859741">1296 Armstrong Ave, San Francisco, CA 94124</a>
                            </h5>
                            <iframe title="map" height="200px" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3155.8625679230336!2d-122.38816814914502!3d37.722903622816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f7f3db50b7825%3A0x70c9b06c3c511fcf!2s1296%20Armstrong%20Ave%2C%20San%20Francisco%2C%20CA%2094124!5e0!3m2!1sen!2sus!4v1569816867777!5m2!1sen!2sus" frameBorder="0" style={{border:0, width: "100%"}} allowFullScreen=""></iframe>
                        </Col>
                    </Row>
                    <p className="text-center">© {new Date().getFullYear()} Ace Roofing Supplies, Inc</p>
                </Container>
            </div>
        )
    }
}
