import React, { Component } from 'react'
import { Container, Row, Col, Card, Image} from 'react-bootstrap'
import { FaInfoCircle } from "react-icons/fa";
import ImageModal from '../image-modal/image-modal';

export default class Contents extends Component {

    constructor(props) {
        super(props);

        this.setModalShow = this.setModalShow.bind(this)
        this.setModalHide = this.setModalHide.bind(this)

        this.state = {
            show: false,
            header: "",
            imageSrc: ""
        }
    }

    setModalShow(show, header, imageSrc) {
        this.setState({
            show: show,
            header: header,
            imageSrc: imageSrc
        })
    }

    setModalHide() {
        this.setState({
            show: false
        })
    }

    render() {
        return (
            <div>
                <Container>
                    <h3 id="product" className="text-center" style={{marginBottom: 20}}>Products</h3>
                    <Row>
                        <Col id="steep" style={{marginBottom: 20}} xs={12} md={6}>
                            <Card.Title className="text-center">Steep Slope</Card.Title>
                            <Card>    
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "GAF TIMBERLINE HD Color", "assets/images/steep/hd_color.jpg")}>
                                        <Image src="assets/images/steep/hd_color.jpg" rounded fluid />
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Rapid Ridge Plus", "assets/images/steep/ridge.jpg")}>
                                        <Image src="assets/images/steep/ridge.jpg" rounded fluid />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "MB SBS Shingle Starter", "assets/images/steep/starter.jpg")}>
                                        <Image src="assets/images/steep/starter.jpg" rounded fluid />
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "SAFEGUARD® 30 Hybrid Underlayment", "assets/images/steep/safeguard.jpg")}>
                                        <Image src="assets/images/steep/safeguard.jpg" rounded fluid />
                                    </Col>
                                </Row>
                                <Card.Body>
                                    <ul>
                                        <li>GAF TIMBERLINE HD <a className="info-icon" href="https://www.gaf.com/en-us/products/timberline-hd"><FaInfoCircle /></a></li>
                                        <li>GAF TIMBERLINE Ultra HD (upon request) <a className="info-icon" href="https://www.gaf.com/en-us/products/timberline-uhd"><FaInfoCircle /></a></li>
                                        <li>Rapid Ridge Plus <a className="info-icon" href="http://www.rapidridgeplus.com/100ProductInfo.asp"><FaInfoCircle /></a></li>
                                        <li>MB SBS Shingle Starter <a className="info-icon" href="https://www.mbtechnology.com/sbs-shingle-starter/"><FaInfoCircle /></a></li>
                                        <li>SAFEGUARD® 30 Hybrid Underlayment <a className="info-icon" href="http://safeguard30.com/safeguard/"><FaInfoCircle /></a></li>
                                        <li>#30 Felt Underlayment</li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col id="low" style={{marginBottom: 20}} xs={12} md={6}>
                            <Card.Title className="text-center">Low Slope</Card.Title>
                            <Card>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "CertainTeed FLINTLASTIC® GTA", "assets/images/low/gta.jpg")}>
                                        <Image src="assets/images/low/gta.jpg" rounded fluid />
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "CertainTeed FLINTLASTIC® STA Smooth", "assets/images/low/sta.jpg")}>
                                        <Image src="assets/images/low/sta.jpg" rounded fluid />
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "MB ECO Ice & Moisture Block SBS 65S Self-adhesive base", "assets/images/low/65s.jpg")}>
                                        <Image src="assets/images/low/65s.jpg" rounded fluid />
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "", "assets/images/low/3.jpg")}>
                                        <Image src="assets/images/low/3.jpg" rounded fluid />
                                    </Col>
                                </Row>
                            <Card.Body>
                                <ul>
                                    <li>CertainTeed FLINTLASTIC® GTA <a className="info-icon" href="https://www.certainteed.com/commercial-roofing/products/flintlastic-gta/"><FaInfoCircle /></a></li>
                                    <li>CertainTeed FLINTLASTIC® STA Smooth <a className="info-icon" href="https://www.certainteed.com/commercial-roofing/products/flintlastic-sta"><FaInfoCircle /></a></li>
                                    <li>POLYGLASS® APP BASE <a className="info-icon" href="https://polyglass.us/product/polyglass-base/"><FaInfoCircle /></a></li>
                                    <li>GAFGLAS® #75 Base Sheet <a className="info-icon" href="https://www.gaf.com/en-us/products/gafglass-75-base-sheet"><FaInfoCircle /></a></li>
                                    <li>MB ECO Ice & Moisture Block SBS 65S Self-adhesive base <a className="info-icon" href="https://www.mbtechnology.com/ice-moisture-block-sbs/"><FaInfoCircle /></a></li>
                                    <li>MB ECOTORCH White <a className="info-icon" href="https://www.mbtechnology.com/ecotorchsbs/"><FaInfoCircle /></a></li>
                                    <li>MB ECOTORCH Smooth <a className="info-icon" href="https://www.mbtechnology.com/ecotorchsbs/"><FaInfoCircle /></a></li>
                                </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col id="sheet-metal" style={{marginBottom: 20}} xs={12} md={6}>
                            <Card.Title className="text-center">Sheet Metals</Card.Title>
                            <Card>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "Skylight", "assets/images/sheetmetal/skylight.jpg")}>
                                        <Image src="assets/images/sheetmetal/skylight.jpg" rounded fluid />                                                                                        
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Skylight", "assets/images/sheetmetal/skylight2.jpg")}>
                                        <Image src="assets/images/sheetmetal/skylight2.jpg" rounded fluid />                                
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "Chimney", "assets/images/sheetmetal/chimney1.jpg")}>
                                        <Image src="assets/images/sheetmetal/chimney1.jpg" rounded fluid />                                                                                        
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Chimney", "assets/images/sheetmetal/chimney2.jpg")}>
                                        <Image src="assets/images/sheetmetal/chimney2.jpg" rounded fluid />                                
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "China Cap", "assets/images/sheetmetal/chinacap.jpg")}>
                                        <Image src="assets/images/sheetmetal/chinacap.jpg" rounded fluid />                                                                                        
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Cap", "assets/images/sheetmetal/cap.jpg")}>
                                        <Image src="assets/images/sheetmetal/cap.jpg" rounded fluid />                                
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "Sheet Metal", "assets/images/sheetmetal/cap1.jpg")}>
                                        <Image src="assets/images/sheetmetal/cap1.jpg" rounded fluid />                                                                                        
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "025 Air Vent", "assets/images/sheetmetal/025.jpg")}>
                                        <Image src="assets/images/sheetmetal/025.jpg" rounded fluid />                                
                                    </Col>
                                </Row>
                                <Card.Body>
                                    <ul>
                                        <li>Skylight</li>
                                        <li>Chimney</li>
                                        <li>China Cap</li>
                                        <li>Combo vent</li>
                                        <li>Drain Outlet</li>
                                        <li>Gravel Stop and Nosing(various sizes)</li>
                                        <li>Conductor Head</li>
                                        <li>All sheet metals for roofing projects</li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col id="gutter" style={{marginBottom: 20}} xs={12} md={6}>
                            <Card.Title className="text-center">Gutter & Accessories</Card.Title>
                            <Card>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "Gutter", "assets/images/gutter/gutter.jpg")}>
                                        <Image src="assets/images/gutter/gutter.jpg" rounded fluid />                                                                                        
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "OG Gutter", "assets/images/gutter/og.jpg")}>
                                        <Image src="assets/images/gutter/og.jpg" rounded fluid />
                                    </Col>
                                    
                                </Row>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "Elbows and Offsets", "assets/images/gutter/elbow.jpg")}>
                                        <Image src="assets/images/gutter/elbow.jpg" rounded fluid />                                                                                        
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Various Downpouts", "assets/images/gutter/pipe.jpg")}>
                                        <Image src="assets/images/gutter/pipe.jpg" rounded fluid />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "Gutter Accessories", "assets/images/gutter/gutter1.jpg")}>
                                        <Image src="assets/images/gutter/gutter1.jpg" rounded fluid />
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Adapter", "assets/images/gutter/adapter.jpg")}>
                                        <Image src="assets/images/gutter/adapter.jpg" rounded fluid />                                                                                                                                
                                    </Col>
                                </Row>
                                <Card.Body>
                                    <ul>
                                        <li>Gutter machine delivery within most of bay area</li>
                                        <li>OG and Fascia Gutter(Aluminum and Copper)</li>
                                        <li>End cap, hanger, drop outlet(various types and sizes)</li>
                                        <li>Downspouts, straps, and elbows(various sizes and degrees)</li>
                                        <li>Inside and outside conners and boxes</li>
                                        <li>Leafblaster Pro by Gutterglove <a className="info-icon" href="https://www.leafblaster.com"><FaInfoCircle /></a></li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col id="paint" style={{marginBottom: 20}} xs={12} md={6}>
                            <Card.Title className="text-center">Roofing Paints</Card.Title>
                            <Card>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "ASC Base Coat", "assets/images/paint/paint1.jpg")}>
                                        <Image src="assets/images/paint/paint1.jpg" rounded fluid />                                                        
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "ASC Eleastomatic Cement", "assets/images/paint/paint2.jpg")}>
                                        <Image src="assets/images/paint/paint2.jpg" rounded fluid />                                                                                    
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "ASC Silicon Primer", "assets/images/paint/paint3.jpg")}>
                                        <Image src="assets/images/paint/paint3.jpg" rounded fluid />                                                        
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Lucas Asphalt Emulsion", "assets/images/paint/paint4.jpg")}>
                                        <Image src="assets/images/paint/paint4.jpg" rounded fluid />                                                       
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "ASC Silicon Coating(White)", "assets/images/paint/paint5.jpg")}>
                                        <Image src="assets/images/paint/paint5.jpg" rounded fluid />                                                      
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "ASC Silicon Coating(Gray)", "assets/images/paint/paint6.jpg")}>
                                        <Image src="assets/images/paint/paint6.jpg" rounded fluid />                                                        
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "Lucas #777 Rubberized Flashing Cement", "assets/images/paint/paint7.jpg")}>
                                        <Image src="assets/images/paint/paint7.jpg" rounded fluid />                                                        
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Liquid Rubber Waterproof Sealant/Coating", "assets/images/paint/paint8.jpg")}>
                                        <Image src="assets/images/paint/paint8.jpg" rounded fluid />                                                      
                                    </Col>
                                </Row>
                                <Card.Body>
                                    <ul>
                                        <li>From Popular Companies, such as American Standard Coating, Lucas, and Liquid Rubber</li>
                                        <li>Base Coat</li>
                                        <li>Eleastomatic Cement</li>
                                        <li>Elastomatic White Roof Coating</li>
                                        <li>Silicon Coating (white and gray)</li>
                                        <li>Silicon Primer</li>
                                        <li>Lucas #777 Rubberized Flashing Cement</li>
                                        <li>Lucas #628 Aluminum Coating</li>
                                        <li>Liquid Rubber Waterproof Sealant/Coating</li>
                                        <li>And more... Call us to check what we carry</li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col id="accessories" style={{marginBottom: 20}} xs={12} md={6}>
                            <Card.Title className="text-center">Roofing accessories and more</Card.Title>
                            <Card>
                                <Row style={{marginBottom: 10}}>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "Roofing accessories", "assets/images/accessories/accessories1.jpg")}>
                                        <Image src="assets/images/accessories/accessories1.jpg" rounded fluid />
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Roofing accessories", "assets/images/accessories/accessories2.jpg")}>
                                        <Image src="assets/images/accessories/accessories2.jpg" rounded fluid />                            
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="padding-right" onClick={() => this.setModalShow(true, "Spray paints, primer, and caulking", "assets/images/accessories/spray.jpg")}>
                                        <Image src="assets/images/accessories/spray.jpg" rounded fluid />
                                    </Col>
                                    <Col className="padding-left" onClick={() => this.setModalShow(true, "Propane tank refill, exchange, and buy", "assets/images/accessories/gas.jpg")}>
                                        <Image src="assets/images/accessories/gas.jpg" rounded fluid />                            
                                    </Col>
                                </Row>
                                <Card.Body>
                                    <ul>
                                        <li>Propane tank refill, exchange, and buy</li>
                                        <li>Nails and Screws (various types and sizes)</li>
                                        <li>Roller Frames and long handles, Roller Covers, and Brushes For Paint (various sizes)</li>
                                        <li>Various knives and blades</li>
                                        <li>Tape Measure</li>
                                        <li>Torch Kit</li>
                                        <li>Brooms, Shovels, and Tear-Off Spades</li>
                                        <li>Roof Bracket (various angles)</li>
                                        <li>Spray Paints, Caulking, and more... Call us to check what we carry</li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <ImageModal show={this.state.show} header={this.state.header} imageSrc={this.state.imageSrc} setModalHide={this.setModalHide} />
            </div>
        )
    }
}
