import React, { Component } from 'react'
import { Container, Navbar, Nav, NavDropdown, Image } from 'react-bootstrap'
import Headroom from 'react-headroom'

export default class Header extends Component {
    render() {
        return (
            <Headroom style={{background: "white"}} >
                <Container>
                    <Navbar collapseOnSelect expand="sm" style={{padding: 0}}>
                        <Navbar.Brand href="#home">
                            <div className="header">
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <div style={{display: "flex", flexDirection: "row", marginTop: "auto"}}>
                                        <Image className="logo" alt="logo" src="assets/images/logo_1.png" />                                
                                        <h4 className="title">Roofing Supplies</h4>
                                    </div>
                                    <div className="sub-title">Most experienced roofing supply in SF</div>                
                                </div>
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="#about">About Us</Nav.Link>
                                <NavDropdown title="Products" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#steep">Steep Slope</NavDropdown.Item>
                                    <NavDropdown.Item href="#low">Low Slope</NavDropdown.Item>
                                    <NavDropdown.Item href="#sheet-metal">Sheet Metals</NavDropdown.Item>
                                    <NavDropdown.Item href="#gutter">Gutter</NavDropdown.Item>
                                    <NavDropdown.Item href="#paint">Roofing Paints</NavDropdown.Item>
                                    <NavDropdown.Item href="accessories">Accessories</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="#hours">Hours</Nav.Link>
                                <Nav.Link href="#contact">Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </Headroom>
        )
    }
}
