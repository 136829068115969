import React, { Component } from 'react'
import { Modal, Image } from 'react-bootstrap'

export default class ImageModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
          imageSrc: ""
        }
      }

    render() {
        return (
            <Modal style={{cursor: "zoom-out"}} show={this.props.show} onHide={this.props.setModalHide} onClick={this.props.setModalHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding: 0}}>
                    <Image style={{width: "100%"}} src={this.props.imageSrc}/>
                </Modal.Body>
            </Modal>
        )
    }
}
