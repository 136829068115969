import React, { Component } from 'react'
import { Container } from 'react-bootstrap'

export default class About extends Component {
    render() {
        return (
            <Container>
                <div id="about" className="about text-center">
                    <h3 style={{paddingBottom: 10}}>About Us</h3>
                    <p>
                    ACE Roofing Supply is a premier provider of your roofing supplies & sheet metal needs. At ACE, our quality service does not end with the delivery of roofing supply & sheet metal - it ends with the successful completion of your project.
                    </p>
                    <p>
                    Our knowledgeable and experienced staff will work with you to make sure that your project is a success.
                    </p>
                </div>
            </Container>
        )
    }
}
